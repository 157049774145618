






import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import UserDTO from "@/dto/UserDTO";
import RouteNames from "@/router/RouteNames";
import Workspace, {WorkspaceType} from "@/dto/auth/Workspace";

const Auth = namespace("Auth");

@Component
export default class Payroll extends Vue {

  @Auth.State("user")
  private currentUser!: UserDTO;

  @Auth.Getter("hasAdministrativePrivileges")
  private hasAdministrativePrivileges!: boolean;

}
